import { memo } from 'react';
import classes from './styles.module.scss';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { appRoutes } from 'routers/appRoutes';
import UserDropdown from './components/UserDropdown';
import { DefaultLogo } from 'assets';

interface HeaderProps {}

const Header = memo((props: HeaderProps) => {
  return (
    <AppBar className={classes.headerWrapper} position="static" color="inherit">
      <Toolbar className={classes.toolBar}>
        <Link to={appRoutes.private.home} className={classes.logo}>
          <img src={DefaultLogo} alt="Logo" />
        </Link>
        <p className={classes.appName}>Interactive Demographics</p>
        <UserDropdown />
      </Toolbar>
    </AppBar>
  );
});

export default Header;
