const apiRoutes = {
  user: {
    login: '/users/login',
    register: '/users/register',
    updateAvatar: '/users/update-avatar',
    updateProfile: '/users/update-profile',
    updatePassword: '/users/update-password',
    getMe: '/users/get-me',
    sendVerifyEmail: '/users/send-verify-email',
    verifyEmail: '/users/verify-email',
    sendForgotPasswordEmail: '/users/send-forgot-password-email',
    checkCode: '/users/check-code',
    resetPassword: '/users/reset-password',
  },
  subscription: {
    checkout: '/subscriptions/checkout',
    pause: '/subscriptions/pause',
    resume: '/subscriptions/resume',
    schedule: '/subscriptions/schedule',
    cancelSchedule: '/subscriptions/cancel-schedule',
  },
  billing: {
    default: '/billings',
  },
  interdemField: {
    default: '/interdem-fields',
    update: '/interdem-fields/:id',
  },
  dataset: {
    upload: '/dataset/upload',
  },
  datasetHistory: {
    default: '/dataset-history',
  },
  dataControl: {
    default: '/data-control',
  },
  interdem: {
    default: '/interdem',
    change: '/interdem/change',
    download: '/interdem/download',
  },
  setup: {
    forecastYear: '/setups/forecast-year',
  },
};

export default apiRoutes;
