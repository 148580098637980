import { Avatar, Box, Divider, Menu, MenuItem, Stack } from '@mui/material';
import { CaretDownIcon, UserPlaceholderImage } from 'assets';
import { Fragment, memo, SyntheticEvent, useState } from 'react';
import classes from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from 'services/authService';
import { setLogoutUserReducer } from 'redux/reducers/User/actionTypes';
import { push } from 'connected-react-router';
import { appRoutes } from 'routers/appRoutes';
import { IReducer } from 'redux/reducers';
import CommonService from 'services/commonService';
import { EUserType } from 'configs/enums';
import pricingPlans from 'configs/plans';

interface UserDropdownProps {}

const UserDropdown = memo((props: UserDropdownProps) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: IReducer) => state?.user);

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url?: string) => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    AuthService.logout();

    dispatch(setLogoutUserReducer());

    dispatch(push(appRoutes.public.login));
  };

  return (
    <Fragment>
      <div className={classes.avatarWrapper} onClick={handleDropdownOpen}>
        <Avatar src={user?.avatar ?? UserPlaceholderImage} />
        <CaretDownIcon />
      </div>

      <Menu
        className={classes.menu}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box sx={{ px: 2, py: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={user?.avatar ?? UserPlaceholderImage} />
            <Stack direction="column" alignItems="start" sx={{ ml: 1 }}>
              <p className={classes.userName}>{CommonService.getFullName(user)}</p>
              <p className={classes.plan}>
                {user?.type === EUserType.Admin
                  ? 'Administrator'
                  : user?.type === EUserType.Demo
                  ? 'Full Access'
                  : user?.subscription
                  ? pricingPlans?.find((item) => item?.id === user?.subscription?.planId)?.title
                  : 'Free Plan'}
              </p>
            </Stack>
          </Box>
        </Box>
        <Divider />
        {user?.type !== EUserType.Demo ? (
          <MenuItem
            onClick={() => {
              dispatch(push(appRoutes.private.account));
            }}
          >
            Account
          </MenuItem>
        ) : null}
        {user?.type === EUserType.Customer ? (
          <MenuItem
            onClick={() => {
              dispatch(push(appRoutes.private.subscription));
            }}
          >
            Subscription
          </MenuItem>
        ) : null}
        {user?.type === EUserType.Admin ? (
          <MenuItem
            onClick={() => {
              dispatch(push(appRoutes.admin.root));
            }}
          >
            Dataset management
          </MenuItem>
        ) : null}
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </Menu>
    </Fragment>
  );
});

export default UserDropdown;
