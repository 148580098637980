import { IPricingPlan } from 'interfaces/plan';
import { EPlan } from './enums';
import { BasicPricingImage, EnterprisePricingImage, StandardPricingImage } from 'assets';

const pricingPlans: IPricingPlan[] = [
  {
    id: EPlan.DailyPass,
    title: 'Daily Pass',
    subtitle: 'A simple start for everyone',
    description: 'This plan costs $5 per day',
    price: 5,
    durationPlaceholder: 'day',
    imageSource: BasicPricingImage,
  },
  {
    id: EPlan.WeeklyExplorer,
    title: 'Weekly Explorer',
    subtitle: 'For medium businesses',
    description: 'This plan costs $15 per week',
    price: 15,
    durationPlaceholder: 'week',
    imageSource: StandardPricingImage,
  },
  {
    id: EPlan.MonthlyPro,
    title: 'Monthly Pro',
    subtitle: 'For big organizations',
    description: 'This plan costs $100 per month',
    price: 100,
    durationPlaceholder: 'month',
    imageSource: EnterprisePricingImage,
  },
];

export default pricingPlans;
