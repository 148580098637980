import { Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { adminRoutes, fullyAllowedRoutes, IRoute, privateRoutes, publicRoutes } from './routers';
import Middleware from './routers/middleware';
import AuthorizedLayout from 'components/Layouts/AuthorizedLayout';
import NonAuthorizedLayout from 'components/Layouts/NonAuthorizedLayout';
import AppStatus from 'components/AppStatus';
import { History } from 'history';
import { AnyAction, Dispatch } from 'redux';
import { useEffect } from 'react';
import { appRoutes } from 'routers/appRoutes';
import { getMeRequest } from 'redux/reducers/User/actionTypes';
import { useSelector } from 'react-redux';
import { IReducer } from 'redux/reducers';
import AdminLayout from 'components/Layouts/AdminLayout';

interface AppProps {
  history: History;
  dispatch: Dispatch<AnyAction>;
}

const App: React.FC<AppProps> = (props: AppProps) => {
  const { history, dispatch } = props;

  const { isLoadingAuth } = useSelector((state: IReducer) => state.status);

  useEffect(() => {
    dispatch(getMeRequest());

    // remove the ResizeObserver error
    const errorHandler = (e: any) => {
      if (e?.message?.includes('ResizeObserver loop completed with undelivered notifications' || 'ResizeObserver loop limit exceeded')) {
        const resizeObserver = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserver) {
          resizeObserver.style.display = 'none';
        }
      }
    };
    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  return (
    <ConnectedRouter history={history}>
      <AppStatus />

      {!isLoadingAuth ? (
        <Switch>
          {publicRoutes?.map((route: IRoute) => (
            <Middleware
              key={route?.path}
              path={route?.path}
              layout={NonAuthorizedLayout}
              component={route?.component}
              isAuthProtected={false}
              isAdminProtected={false}
              isFullyAllowed={false}
              exact
            />
          ))}

          {privateRoutes?.map((route: IRoute) => (
            <Middleware
              key={route?.path}
              path={route?.path}
              layout={AuthorizedLayout}
              component={route?.component}
              isAuthProtected={true}
              isAdminProtected={false}
              isFullyAllowed={false}
              exact
            />
          ))}

          {fullyAllowedRoutes?.map((route: IRoute) => (
            <Middleware
              key={route?.path}
              path={route?.path}
              layout={NonAuthorizedLayout}
              component={route?.component}
              isAuthProtected={false}
              isAdminProtected={false}
              isFullyAllowed={true}
              exact
            />
          ))}

          {adminRoutes?.map((route: IRoute) => (
            <Middleware
              key={route?.path}
              path={route?.path}
              layout={AdminLayout}
              component={route?.component}
              isAuthProtected={true}
              isAdminProtected={true}
              isFullyAllowed={false}
              exact
            />
          ))}
          <Redirect from={appRoutes.admin.root} to={appRoutes.admin.importDataset} />

          <Redirect to={appRoutes.public.login} />
        </Switch>
      ) : null}
    </ConnectedRouter>
  );
};

export default App;
