import { Card, CardContent, Grid } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import PricingHeader from './components/PricingHeader';
import { IPricingPlan } from 'interfaces/plan';
import PricingFooter from './components/PricingFooter';
import { EPlan, EUserType } from 'configs/enums';
import PlanDetails from './components/PlanDetails';
import ApiService from 'services/apiService';
import apiRoutes from 'configs/apiRoutes';
import ToastService from 'services/toastService';
import Messages from 'configs/messages';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import pricingPlans from 'configs/plans';
import { IReducer } from 'redux/reducers';
import { push } from 'connected-react-router';
import { appRoutes } from 'routers/appRoutes';
import { setUserReducer } from 'redux/reducers/User/actionTypes';
import { IConfirmModal } from 'interfaces/common';
import ConfirmModal from 'components/Modals/ConfirmModal';

interface PricingPageProps {}

const PricingPage: React.FC<PricingPageProps> = memo((props: PricingPageProps) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: IReducer) => state?.user);

  const [confirmModal, setConfirmModal] = useState<IConfirmModal>(null);

  useEffect(() => {
    if (user?.type !== EUserType.Customer) {
      dispatch(push(appRoutes.private.home));
    }
  }, [user]);

  const onSubmitPlan = (planId: EPlan) => {
    dispatch(setIsLoadingReducer(true));
    if (user?.subscription) {
      if (user?.subscription?.scheduledPlanId) {
        ApiService.POST(apiRoutes.subscription.cancelSchedule)
          .then(async (cancelScheduleResponse) => {
            dispatch(setUserReducer(cancelScheduleResponse?.data));
            await ApiService.POST(apiRoutes.subscription.schedule, {
              planId,
            })
              .then((scheduleResponse) => {
                onCloseModals();
                ToastService.success(Messages.success.default);
                dispatch(setUserReducer(scheduleResponse?.data));
                dispatch(push(appRoutes.private.subscription));
              })
              .catch((error) => {
                console.log(error);
                ToastService.error(Messages.error.default);
              })
              .finally(() => dispatch(setIsLoadingReducer(false)));
          })
          .catch((error) => {
            console.log(error);
            ToastService.error(Messages.error.default);
          })
          .finally(() => dispatch(setIsLoadingReducer(false)));
      } else {
        ApiService.POST(apiRoutes.subscription.schedule, {
          planId,
        })
          .then((response) => {
            onCloseModals();
            ToastService.success(Messages.success.default);
            dispatch(setUserReducer(response?.data));
            dispatch(push(appRoutes.private.subscription));
          })
          .catch((error) => {
            console.log(error);
            ToastService.error(Messages.error.default);
          })
          .finally(() => dispatch(setIsLoadingReducer(false)));
      }
    } else {
      ApiService.POST(apiRoutes.subscription.checkout, {
        planId,
      })
        .then((response) => {
          window.location.href = response.data;
        })
        .catch((error) => {
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    }
  };

  const onCloseModals = () => {
    setConfirmModal(null);
  };

  return (
    <Card sx={{ m: 2, boxShadow: 'var(--commonBoxShadow)' }}>
      <CardContent sx={{ minHeight: 'calc(100vh - 64px - 16px - 16px)', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <PricingHeader />

        <Grid container justifyContent="center" spacing={6} mb={4}>
          {pricingPlans?.map((plan: IPricingPlan, planIndex: number) => (
            <Grid item xs={12} sm={6} md={4} key={planIndex}>
              <PlanDetails
                data={plan}
                onClick={(planId: EPlan) => {
                  if (user?.subscription && user?.subscription?.scheduledPlanId) {
                    setConfirmModal({
                      isOpen: true,
                      title: 'Subscription schedule existed',
                      description: 'Scheduling this plan will cancel your existing subscription schedule. Are you sure?',
                      onSubmit: () => {
                        onSubmitPlan(planId);
                      },
                    });
                  } else if (user?.subscription && !user?.subscription?.isRecurring) {
                    setConfirmModal({
                      isOpen: true,
                      title: 'Subscription schedule',
                      description: 'Your subscription is paused, scheduling this plan will resume your subscription. Are you sure?',
                      onSubmit: () => {
                        onSubmitPlan(planId);
                      },
                    });
                  } else {
                    onSubmitPlan(planId);
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>

        <PricingFooter />
      </CardContent>

      <ConfirmModal
        isOpen={confirmModal?.isOpen}
        title={confirmModal?.title}
        description={confirmModal?.description}
        onSubmit={confirmModal?.onSubmit}
        onClose={onCloseModals}
      />
    </Card>
  );
});

export default PricingPage;
