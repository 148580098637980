import { Card, CardContent, Divider, Grid, Stack } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { MAX_IMAGE_SIZE } from 'configs/constant';
import ToastService from 'services/toastService';
import Messages from 'configs/messages';
import { UploadIcon, UserPlaceholderImage } from 'assets';
import Input from 'components/Input';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { IReducer } from 'redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/apiService';
import apiRoutes from 'configs/apiRoutes';
import { setUserReducer } from 'redux/reducers/User/actionTypes';
import * as Yup from 'yup';
import Regexes from 'configs/regexes';
import { IChangePasswordFormData, IUpdateInformationFormData } from 'interfaces/user';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CryptoJS from 'crypto-js';
import { EStatusCode, EUserType } from 'configs/enums';
import { push } from 'connected-react-router';
import { appRoutes } from 'routers/appRoutes';

interface AccountPageProps {}

const AccountPage = memo((props: AccountPageProps) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: IReducer) => state?.user);

  const imageInputRef = useRef<HTMLInputElement>();

  const [avatar, setAvatar] = useState<string>(null);
  const [image, setImage] = useState<File>(null);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required('Please enter your first name.'),
        lastName: Yup.string().required('Please enter your last name.'),
        phone: Yup.lazy((value) => (!value ? Yup.string() : Yup.string().matches(Regexes.phoneNumber, 'Please enter a valid phone number.'))),
      }),
    []
  );

  const changePasswordValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        oldPassword: Yup.string().required('Please enter your old password.'),
        newPassword: Yup.string()
          .required('Please enter your new password.')
          .matches(Regexes.password, 'Password must be least 8 characters including an upper case, an lower case, a number, and a special character.'),
        confirmNewPassword: Yup.string()
          .required('Please confirm your new password.')
          .oneOf([Yup.ref('newPassword'), null], 'Confirm new password does not match.'),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IUpdateInformationFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    register: changePasswordRegister,
    handleSubmit: changePasswordHandleSubmit,
    reset: changePasswordReset,
    formState: { errors: changePasswordErrors },
  } = useForm<IChangePasswordFormData>({
    resolver: yupResolver(changePasswordValidationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (user?.type === EUserType.Demo) {
      dispatch(push(appRoutes.private.home));
    }
    if (user) {
      setAvatar(user?.avatar ?? UserPlaceholderImage);
      reset({
        firstName: user?.firstName,
        lastName: user?.lastName,
        phone: user?.phone,
      });
    }
  }, [user]);

  useEffect(() => {
    if (image) {
      onSubmitImage();
    } else {
      imageInputRef.current.value = '';
    }
  }, [image]);

  const onChangeImage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const imageFile: File = event?.target?.files?.[0];
    if (imageFile?.size >= MAX_IMAGE_SIZE) {
      return ToastService.error(Messages.error.imageSize);
    }

    if (imageFile) {
      setImage(imageFile);
    }
  }, []);

  const onSubmitImage = () => {
    dispatch(setIsLoadingReducer(true));
    const formData = new FormData();
    formData.append('avatar', image);
    ApiService.PUT(apiRoutes.user.updateAvatar, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        const updatedUser = {
          ...user,
          avatar: response?.data?.avatar ?? user?.avatar,
        };
        dispatch(setUserReducer(updatedUser));
        setImage(null);
        ToastService.success(Messages.success.default);
      })
      .catch((error) => {
        setImage(null);
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onSubmitInformation = (data: IUpdateInformationFormData) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.PATCH(apiRoutes.user.updateProfile, {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone || null,
    })
      .then((response) => {
        const updatedUser = {
          ...user,
          ...response?.data,
        };
        dispatch(setUserReducer(updatedUser));
        ToastService.success(Messages.success.default);
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onSubmitChangePassword = (data: IChangePasswordFormData) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.PUT(apiRoutes.user.updatePassword, {
      oldPassword: CryptoJS.AES.encrypt(data.oldPassword, process.env.REACT_APP_AES_KEY).toString(),
      newPassword: CryptoJS.AES.encrypt(data.newPassword, process.env.REACT_APP_AES_KEY).toString(),
    })
      .then(() => {
        changePasswordReset();
        ToastService.success(Messages.success.default);
      })
      .catch((error) => {
        console.log([error]);
        ToastService.error(error?.response?.status === EStatusCode.BadRequest ? Messages.error.wrongOldPassword : Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  return (
    <div className={classes.profileWrapper}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ borderRadius: '10px' }}>
            <p className={classes.cardHeader}>Update profile</p>

            <form onSubmit={handleSubmit(onSubmitInformation)}>
              <CardContent>
                <Stack className={classes.avatarWrapper} direction="row" flexWrap="wrap" alignItems="center">
                  <div className={classes.avatar} onClick={() => imageInputRef?.current?.click()}>
                    <input
                      ref={imageInputRef}
                      type="file"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeImage(event)}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                    <img src={avatar ?? UserPlaceholderImage} alt="Avatar" />
                    <UploadIcon />
                  </div>
                  <p>Allowed JPG, JPEG or PNG. Max size of 5MB</p>
                </Stack>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container columnSpacing={6}>
                  <Grid item xs={12} sm={6}>
                    <Input
                      label="First name"
                      type="text"
                      placeholder="Your first name"
                      autoComplete="given-name"
                      inputRef={register('firstName')}
                      errorMessage={errors?.firstName?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Input
                      label="Last name"
                      type="text"
                      placeholder="Your last name"
                      autoComplete="family-name"
                      inputRef={register('lastName')}
                      errorMessage={errors?.lastName?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Input
                      label="Phone number"
                      type="text"
                      placeholder="Your phone number"
                      autoComplete="tel"
                      isOptional
                      inputRef={register('phone')}
                      errorMessage={errors?.phone?.message}
                    />
                  </Grid>
                </Grid>
                <PrimaryButton>Save changes</PrimaryButton>
              </CardContent>
            </form>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ borderRadius: '10px' }}>
            <p className={classes.cardHeader}>Change password</p>

            <form onSubmit={changePasswordHandleSubmit(onSubmitChangePassword)}>
              <CardContent>
                <Grid container columnSpacing={6}>
                  <Grid item xs={12} sm={6}>
                    <Input
                      label="Old password"
                      type="password"
                      placeholder="Your old password"
                      inputRef={changePasswordRegister('oldPassword')}
                      errorMessage={changePasswordErrors?.oldPassword?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Input
                      label="New password"
                      type="password"
                      placeholder="Your new password"
                      inputRef={changePasswordRegister('newPassword')}
                      errorMessage={changePasswordErrors?.newPassword?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Input
                      label="Confirm new password"
                      type="password"
                      placeholder="Conform your new password"
                      inputRef={changePasswordRegister('confirmNewPassword')}
                      errorMessage={changePasswordErrors?.confirmNewPassword?.message}
                    />
                  </Grid>
                </Grid>
                <PrimaryButton type="submit">Update password</PrimaryButton>
              </CardContent>
            </form>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
});

export default AccountPage;
